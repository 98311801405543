
import { Component, Mixins, Vue } from 'vue-property-decorator'
import LoginMethods from '@/components/organisms/LoginMethods.vue'

@Component
export default class GakkenSamlAcs extends Mixins(LoginMethods) {
  private loginHash: string = this.$route.query.loginHash as string

  private async created() {
    Vue.prototype.$loading.start()
    this.mode = this.MODE.STUDENT

    // axiosにてerrorレスポンスがあった場合は生徒ログイン画面に飛ばす
    Vue.prototype.$http.http.interceptors.response.use(
      (reponse: any) => reponse,
      async (error: any) => {
        // エラー処理
        alert('エラーが発生しました。')
        window.location.href = '/student/login'
        return Promise.reject(error)
      }
    )

    // SAML認証ログインを実行する
    await this.samlLogin(this.loginHash)

    Vue.prototype.$loading.complete()
  }
}
